.account-form {
  background-color: #f2f2f2;
  margin-top: 3em;
  margin-bottom: 2em;
  padding: 0 2em 0.5rem 2em;
  .message {
    padding: 0.5em;
    margin-top: 1.75rem;
    background: #fff;
  }
  .form-title {
    position: relative;
    top: -1em;
    h5 {
      background-color: var(--primary);
      color: #f2f2f2;
      padding: 0.5em 1em;
      margin: 0;
    }
  }
}
