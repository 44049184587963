.recruit-manager {
  .jumbotron {
    margin-bottom: 0;
    background: linear-gradient(0deg, rgba(37, 27, 33, 0.45), rgba(37, 27, 33, 0.45)),
      url('/img/banners/9-giro-2018.jpg');
    background-size: cover;
    background-position: 60% 30%;
    color: #dfdfdf;
  }
  .recruit-columns {
    padding-top: 2em;
    .recruit-column {
      margin: 8px;
      border-radius: 2px;
      .recruit-list {
        min-height: 80%;
        &.dragging-over {
          background-color: lightblue;
        }
      }
    }
  }
}
