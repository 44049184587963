.connection-requests {
  .jumbotron {
    background: linear-gradient(0deg, rgba(37, 27, 33, 0.45), rgba(37, 27, 33, 0.45)),
      url('/img/banners/giro-corner-2018.jpg');
    background-size: cover;
    background-position: 60% 30%;
    color: #dfdfdf;
  }
  .grey-bg {
    background: #dfdfdf;
  }
  .nav {
    .nav-link:not(.active) {
      background: #dfdfdf;
      color: #000000;
    }
  }
}
.card-container {
  // border: 1px solid red;
  background-color: #f2f2f2;
}
