.section-title {
  position: relative;
  top: -1.5em;
  padding-left: 2em;
  h5 {
    background-color: var(--primary);
    color: #f2f2f2;
    padding: 0.5em 1em;
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .section-title {
    padding-left: 0;
  }
}
