﻿.navbar-brand {
  height: 50px;
  width: 78px;
  svg {
    width: 100%;
    height: auto;
    path {
      fill: #fff;
    }
  }
}

.navbar-dark {
  .navbar-nav {
    .rego-nav {
      background: #f95220;
      .active {
        color: #fff;
      }
    }
    .rego-nav {
      background: #f95220;
      .nav-link.active {
        color: #fff;
        box-shadow: inset 0 0px 0 white, inset 0 -2px 0 white;
      }
    }
    .nav-link.active,
    .nav-link:hover,
    .nav-link:focus {
      color: #ff7209;
    }
  }
}

.navbar {
  &.bg-rider {
    background-color: $rider-blue;
  }
  &.bg-team {
    background-color: $team-yellow;
    .nav-link {
      &:hover,
      &.active {
        background: #fff;
        color: #222321;
      }
    }
  }
  @media (max-width: 768px) {
    .navbar-toggler {
      border: none;
      .icon-bar {
        border: none;
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        margin: 4px 0 4px 0;
        transition: all 0.35s;
        background: #fff;
      }
      &:focus {
        outline: none;
      }
      &.open {
        .icon-bar {
          &:nth-of-type(1) {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
          }
          &:nth-of-type(2) {
            opacity: 0;
            filter: alpha(opacity=0);
          }
          &:nth-of-type(3) {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
          }
        }
      }
    }
    .navbar-collapse {
      position: absolute;
      top: 72px;
      left: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      width: 100%;
      .navbar-nav {
        flex-direction: row;
        flex-wrap: wrap;
        .nav-item {
          text-align: center;
          padding: 2rem;
          width: 50%;
        }
      }
      &.collapsing {
        height: auto;
        transition: all 0.15s ease-in;
        left: -100%;
      }
      &.show {
        left: 0;
        height: calc(100vh - 54px);
        z-index: 10;
        background: rgba(10, 10, 10, 0.85);
        transition: all 0.15s linear;
      }
    }
  }
}
