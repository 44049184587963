.signup-for-cc {
  background: #f5f5f5;
  border-top: 2px #ddd solid;
  border-bottom: 2px #ddd solid;
  margin-bottom: 0;
}
.divider {
  width: 1em;
  height: auto;
  display: inline-block;
}