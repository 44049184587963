@import '~react-redux-toastr/src/styles/index';
$rider-blue: #2878ce;
$rider-blue-hover: #205a98;
$team-yellow: #ffcc00;
$team-yellow-hover: #e4b700;
@import 'sass/modules';
.fade-enter {
  opacity: 0;
  z-index: 1;
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
}
// team and rider helpers
.btn-team {
  color: #fff;
  background: $team-yellow;
  &:hover {
    background-color: $team-yellow-hover;
    box-shadow: inset 0 0px 0 white, inset 0 -2px 0 white;
    border-bottom: 1px solid #fff;
  }
}

.btn-outline-team {
  color: $team-yellow;
  background-color: transparent;
  background-image: none;
  border-color: $team-yellow;
  &:hover {
    color: #fff;
    background: $team-yellow;
    box-shadow: inset 0 0px 0 white, inset 0 -2px 0 white;
    border-bottom: 1px solid #fff;
  }
}
.btn-rider {
  color: #fff;
  background-color: $rider-blue;
  &:hover {
    color: #fff;
    background: $rider-blue-hover;
    border-color: $rider-blue-hover;
    box-shadow: inset 0 0px 0 white, inset 0 -2px 0 white;
    border-bottom: 1px solid #fff;
  }
}
.btn-outline-rider {
  color: $rider-blue;
  background-color: transparent;
  background-image: none;
  border-color: $rider-blue;
  &:hover {
    color: #fff;
    background: $rider-blue;
    box-shadow: inset 0 0px 0 white, inset 0 -2px 0 white;
    border-bottom: 1px solid #fff;
  }
}

.opacity-5 {
  opacity: 0.5;
}

.jumbotron {
  &.smaller {
    padding: 2em;
  }
}
.blockquote {
  p {
    margin: 0.5rem 0;
  }
}
@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
    padding-top: 0px;
  }
}
@media (min-width: 768px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
    padding-top: 0px;
  }
}
.input-group-text {
  border-width: 1px;
  // border: 1px solid rgba(40, 120, 206, 0.5);
}

a {
  color: #ff7209;
  &:hover {
    color: #ff3409;
  }
}

.light-grey-bg {
  background-color: #f6f5f5;
}
.grey-bg {
  background-color: #f2f2f2;
}

.blockquote {
  border-left: 3px solid #000;
  padding-left: 1rem;
  @extend .grey-bg;
}

.display-5 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}
.shadow {
  transition: all 0.25s ease-in-out;
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(47, 47, 47, 0.45) !important;
  }
}

.admin-index {
  .card:hover {
    text-decoration: none;
  }
}

.news-admin .jumbotron {
  background: linear-gradient(0deg, rgba(37, 27, 33, 0.25), rgba(37, 27, 33, 0.25)),
    url('/img/banners/news.jpg');
  background-size: cover;
  background-repeat: none;
  background-position: 60% 40%;
  color: #ddd;
}
.tp-tooltip {
  border: 1px solid #e2e2e2;
  padding: 0.5rem;
  background: #ffffff;
}
