.react-date-picker {
  z-index: 2;
  &.team {
    border: 1px solid rgba($team-yellow, 0.5);
    border-radius: 2px;
    &:hover {
      border-color: $team-yellow;
    }
  }
  input {
    border: none !important;
  }
  max-height: 37.5px;
  .react-date-picker__wrapper {
    width: 100%;
    border: none;
    .react-date-picker__inputGroup {
      .react-date-picker__inputGroup__leadingZero {
        color: #000;
        padding-left: 0.125em;
      }

      .react-date-picker__inputGroup__month,
      .react-date-picker__inputGroup__day {
        min-width: 1.25em;
        &.react-date-picker__inputGroup__input--hasLeadingZero {
          min-width: 0.75em;
        }
      }
      .react-date-picker__inputGroup__year {
        min-width: 2.5em;
      }
    }
    .react-date-picker__button {
      padding: 0 4px;
    }
  }
}
.add-notice {
  .react-date-picker__calendar {
    width: 325px;
  }
}
