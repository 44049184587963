.team-search-result {
  border: 1px solid #dfdfdf;
  border-radius: 0.2em;
  .card {
    border-color: #dfdfdf;
  }
  .team-name {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .card-body {
    padding: 10px;
  }
  .card-footer {
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #dfdfdf;
  }
}

