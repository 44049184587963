.geosuggest__suggests {
  background-color: #fff;
  padding-left: 0;
  position: absolute;
  left: 0;
  right: 0;
  max-height: 25em;
  margin-top: -1px;
  margin-left: 15px;
  margin-right: 15px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  transition: max-height 0.2s, border 0.2s;
  .geosuggest__item {
    padding: 0.25em 1em;
    cursor: pointer;
  }
  .geosuggest__item:hover {
    background-color: #f2f2f2;
  }
  li:last-of-type {
    border-bottom: 2px solid #3498db;
  }
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}