.account-form.floating-button {
  padding-top: 2em;
}

/* .phantom {
  height: 66px;
  margin-top: 2em;
  margin-bottom: 2em;
}

.floating-button {
  position: fixed;
  bottom: 200px;
  left: 0;
  right: 0;
  margin-bottom: 0;
  border-top: 1px solid #ddd;
}

@media (max-width: 575.98px) {
  .account-form.floating-button {
    bottom: 0;
    margin-bottom: 0px;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media (max-width: 767.98px) {
  .account-form.floating-button {
    bottom: 0;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media (max-width: 991.98px) {
  .account-form.floating-button {
    bottom: 0;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

/* @media (max-width: 1199.98px) {
  .floating-button {
    max-width: 1140px;

  }
} */ 