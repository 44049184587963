.account-container.registration {
  & + .footer {
    display: none;
  }
}
.account-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: none;
    background-image: url(/img/banners/40-giro-2018.jpg);
    filter: saturate(50%);
  }
  .account-form {
    background-color: rgba(242, 242, 242, 0.75);
    border-radius: 2px;
  }
}
.registration {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &.account-container .account-form {
    background-color: rgba(242, 242, 242, 0.85);
    border: 1px solid var(--primary);
    border-radius: 2px;
    .form-control {
      border-radius: 2px;
      border: 1px solid var(--primary);
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: none;
  }

  &.login {
    &::before {
      background-image: url(/img/banners/TTTW2018.jpg);
      filter: saturate(50%);
    }
  }
  &.team {
    .account-form {
      border-color: $team-yellow;
      .form-control {
        border: 1px solid $team-yellow;
      }
    }

    input:focus {
      border-bottom-color: $team-yellow;
    }
    &::before {
      background-image: url(/img/banners/9-giro-2018.jpg);
      filter: saturate(50%);
    }
  }
  &.rider {
    .account-form {
      border-color: $rider-blue;
      .form-control {
        border: 1px solid $rider-blue;
      }
    }

    input:focus {
      border-bottom-color: $rider-blue;
    }
    &::before {
      background-image: url(/img/banners/giro-rosa-bridge-2018.jpg);
      filter: saturate(50%);
    }
  }
  .account-form {
    margin-top: 0;
  }
}
