.team-openings {
  .profile-img {
    svg {
      color: #ddd;
    }
  }
  a {
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }
  .open-team-wrapper {
    height: 400px;
    max-height: 50vh;
    overflow: auto;
  }

  .open-team {
    background: #fff;
    padding: 8px;
  }
}
