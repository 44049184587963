.notification-card {
  a {
    color: #000;
    text-decoration: none;
  }
  background-color: #fff;
  padding: 8px;
  margin-bottom: 0.7rem;
  transition: background-color 350ms ease-in-out;
  border-bottom: 2px solid transparent;
  &.unread {
    border-bottom: 2px solid var(--success);
  }
  &:hover {
    background-color: #edf2fa;
  }
  .body {
    font-size: 0.85rem;
  }
  .meta {
    font-size: 0.75rem;
    .actions {
      font-size: 0.9rem;
    }
  }
}
