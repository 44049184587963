@keyframes wiggle {
  0%, 100% {
    background-position: 0 50px, 25px 25px
  }
  25%, 75% {
    background-position: 50px 0px, 75px 75px
  }
   
}
.kom-loader {
  font-size: 1.25rem;
  height: 200px;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  background: white;
  background-image:
    radial-gradient(red 25%, transparent 0), 
    radial-gradient(red 25%, transparent 0);
  background-size: 150px 150px;
  background-position: 0 0, 75px 75px;
  animation: wiggle 3s infinite;
}