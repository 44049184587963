.note-card {
  border-radius: 2px;
  //   .note-body {
  //   }
  .note-meta {
    font-size: 14px;
    text-align: right;
    font-style: italic;
  }
}
