.team-card {
  background: #fff;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  .team {
    padding: 8px 8px 8px;
  }
  .actions {
    .action {
      padding-top: 8px;
      padding-bottom: 8px;
      transition: background-color 0.2s ease;
    }
  }
}
