.news-index {
  .banner {
    background: linear-gradient(0deg, rgba(37, 27, 33, 0.25), rgba(37, 27, 33, 0.25)),
      url('/img/banners/news.jpg');
    background-size: cover;
    background-repeat: none;
    background-position: 60% 40%;
    color: #ddd;
  }
  .card.shadow {
    transition: all 0.25s ease-in-out;
    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(47, 47, 47, 0.45) !important;
    }
    .text-muted {
      font-size: 0.777rem;
    }
  }
}
