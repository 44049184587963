@keyframes stripe-animation {
  0% {
    width:1px;
  }
  100% {
    width:100%;
  }
}

.stripes {
  height: 200px;
  .stripe {
    height: 20%;
    width: 1px;
    animation: stripe-animation forwards 3s;
  }
  .blue {
    background: blue;
  }
  .red {
    background: red;
  }
  .black {
    background: black;
  }
  .yellow {
    background: yellow;
  }
  .green {
    background: green;
  }
}

