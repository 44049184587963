.recruit-container {
  // margin-bottom: 8px;
  border-radius: 2px;
  background-color: white;
  .recruit {
    padding: 8px 8px 8px;
    .placeholder {
      color: #ddd;
    }
  }
  .actions {
    .action {
      padding-top: 8px;
      padding-bottom: 8px;
      transition: background-color 0.2s ease;
    }
    .note {
      background: rgb(253, 235, 132);
      &:hover {
        background: #e6d577;
      }
    }
    .contact {
      background: paleturquoise;
      &:hover {
        background: #8df8f8;
      }
    }
    .profile {
      background: #6ae06a;
      &:hover {
        background: #33dd33;
      }
    }
  }
}
.rider-card {
  background: #fff;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  .rider {
    padding: 8px 8px 8px;
    .placeholder {
      color: #ddd;
    }
  }
  .actions {
    .action {
      padding-top: 8px;
      padding-bottom: 8px;
      transition: background-color 0.2s ease;
    }
    .approve,
    .connect {
      background: var(--success);
      &:hover {
        background: #33dd33;
      }
    }
    .decline,
    .revoke {
      background: var(--danger);
      &:hover {
        background: #e3453d;
      }
    }
    .decline-follow,
    .unfollow {
      background: var(--warning);
      &:hover {
        background: #ff9c00;
      }
    }

    .note {
      background: rgb(253, 235, 132);
      &:hover {
        background: #e6d577;
      }
    }
    .contact {
      background: paleturquoise;
      &:hover {
        background: #8df8f8;
      }
    }
    .profile {
      background: var(--primary);
      color: #dfdfdf;
      &:hover {
        background: #2c67a5;
      }
    }
  }
}
