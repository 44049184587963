.form-label {
  position: absolute;
  background-color: var(--primary);
  padding: 0.5rem 1rem;
  color: #fff;
  top: -1.5rem;
  font-size: 1.25rem;
  transform: rotate(-3deg);
  &.rider {
    background-color: $rider-blue;
  }
  &.team {
    background-color: $team-yellow;
  }
}
