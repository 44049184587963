.team-profile-edit-container {
  .nav {
    .nav-link:not(.active) {
      background: #dfdfdf;
      color: #000000;
    }
  }
}
.profile-edit-banner {
  padding: 2em;
  margin-bottom: 0;
  h2 {
    color: #f2f2f2;
  }
}

.team-profile-edit {
  .account-form {
    background: #fff;
    border-radius: 3px;
    select,
    input,
    textarea {
      border: 1px solid rgba($team-yellow, 0.5);
      border-radius: 2px;
      &:hover,
      &:focus {
        border-color: rgba($team-yellow, 1);
      }
    }
    .yes-no-switch {
      .btn:not(.active) {
        background: #fff;
        border: 1px solid $team-yellow;
        color: $team-yellow;
      }
      .btn.active {
        box-shadow: inset 0 0px 0 white, inset 0 -2px 0 white;
      }
    }
  }
  .team-profile-nav {
    padding-left: 0;
    padding-right: 0;
    .nav {
      .nav-item {
        text-align: left;
        background: #fdfdfd;
        border-bottom: 1px solid #dfdfdf;
        border-left: 3px solid transparent;
        transition: height 0ms 400ms, opacity 400ms 0ms;
        &:hover,
        &.active {
          background: white;
          border-left: 3px solid $team-yellow;
          .nav-link {
            color: $team-yellow;
          }
        }
        .nav-link {
          color: $team-yellow-hover;
          svg {
            display: none;
          }
        }
      }
    }
    @media (max-width: 575px) {
      padding-left: 15px;
      .nav {
        margin-bottom: 2rem;
        &.open {
          .nav-item {
            opacity: 1;
            height: auto;
            transition: height 0ms 0ms, opacity 600ms 0ms;
            svg {
              transform: rotate(180deg);
            }
          }
        }
        .nav-item {
          opacity: 0;
          height: 0;
          &.active {
            opacity: 1;
            height: auto;
            svg {
              display: inherit;
              position: absolute;
              right: 25px;
              top: 12px;
            }
          }
        }
      }
    }
  }
}
