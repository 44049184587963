.user-announcements {
  background: #60bb71;
  color: #fff;
  .announcement {
    border-bottom: 1px solid white;
    padding: 0.5rem 0;
    .container {
      display: flex;
      p {
        margin: 0;
        a {
          color: blue;
          text-decoration: underline;
        }
      }
      .content {
        flex: 1 1 auto;
      }
      .hide {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}
