.search-result {
  padding: 10px;
  background: #f2f2f2;
}

.search-results .card-body {
  /* padding-top: 0.5em; */
}

.search-results {
  .highlight-row {
    padding-top: 1em;
  }
  .details-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.detail-card {
  border-radius: 3px;
  border: 1px solid #dfdfdf;
  min-width: 75px;
  height: 75px;
  background: #fdfdfd;
  flex: 0 0 auto;
  .data {
    width: 100%;
    height: 65%;
    font-size: 1.25em;
    padding: 10px;
  }
  
  .data.flag {
    padding: 5px;
    min-width: 100px;
  }
  
   .detail-label {
    border-top: 1px solid #dfdfdf;
    width: 100%;
    vertical-align: bottom;
    background-color: #dfdfdf;
    height: 36%;
    font-weight: bold;
  }
}