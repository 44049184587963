.home-container {
  padding-top: 32px;
}

.header-banner {
  background: linear-gradient(0deg, rgba(37, 27, 33, 0.525), rgba(37, 27, 33, 0.525)),
    url('/img/banners/giro-2018.jpg');
  background-size: cover;
  background-position: 40% 50%;
  color: #fff;
  margin-bottom: 0;
  padding: 8rem 2rem;
  .lead {
    font-size: 1.7rem;
  }
  .cta {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 67px;
    .btn {
      font-size: 1.5rem;
    }
  }
}

.two-sides {
  display: grid;
  background: #000;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2px;
  justify-content: space-between;
  align-items: stretch;
  border-bottom: #000 solid 1px;
  .for-teams,
  .for-riders {
    border: 8px solid #fff;
    min-height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      transform: rotate(-5deg);
      color: #fff;
      font-size: 68px;
      font-family: 'roboto', sans-serif;
      font-weight: 900;
      line-height: 1.1;
      letter-spacing: 3px;
      text-decoration: underline;
      text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .for-teams {
    background: $team-yellow;
  }
  .for-riders {
    background: $rider-blue;
  }
}
.info {
  margin-top: 1rem;
  padding-bottom: 1rem;
  min-height: 35rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .col-12 {
    position: relative;
    padding-top: 1rem;
    .label {
      position: absolute;
      font-size: 2rem;
      padding: 0 0.875rem;
      display: inline-block;
      top: -3px;
      left: 0px;
      transform: rotate(-3deg);
      color: #fff;
      font-weight: 600;
      &.rider {
        background: $rider-blue;
      }
      &.team {
        background: $team-yellow;
      }
    }
    .btn-rider {
      background: $rider-blue;
      color: #fff;
      &:hover {
        background-color: $rider-blue-hover;
      }
    }
    .btn-team {
      color: #fff;
      background: $team-yellow;
      &:hover {
        background-color: $team-yellow-hover;
      }
    }
  }
}
.using-platform {
  background: var(--gray-dark);
  margin-bottom: 0;
  .platform-title {
    overflow: hidden;
    &:before,
    &:after {
      background-color: #ddd;
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &:before {
      right: 0.5em;
      margin-left: -50%;
    }

    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }
  h4,
  h5 {
    color: #ddd;
  }
  .triangles .col-md-8 {
    position: relative;
    background-clip: content-box;
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0px;
      bottom: 0px;
      width: 35px;
      height: 100%;
      z-index: 123;
    }
    &:before {
      left: -20px;
      background: #f2f2f2;
      clip-path: polygon(100% 0, 100% 100%, 0 50%);
    }
    &:after {
      right: -20px;
      background: #f2f2f2;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      width: 36px;
    }
  }
}
