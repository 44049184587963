.race-selector {
  .card-header {
    border: 1px solid #dfdfdf;
  }
  .race-item {
    position: relative;
    transition: all 0.25s ease-in-out;
    &:hover {
      background: var(--info);
      color: white;
    }

    &.active {
      background-color: $team-yellow;
      border-color: $team-yellow-hover;
      &::after {
        content: '';
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 7px 10.5px 7px 0px;
        border-color: transparent #fff transparent transparent;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        transform: rotate(180deg);
      }
    }
  }
}
