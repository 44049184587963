.search-wrapper .jumbotron {
  background-size: cover;
  background-repeat: none;
  color: #dfdfdf;
  &.rider-header {
    background: linear-gradient(0deg, rgba(37, 27, 33, 0.25), rgba(37, 27, 33, 0.25)), url('/img/banners/giro-2018.jpg');
    background-position: 60% 50%;
  }
}

.input-range .input-range__label--min .input-range__label-container,
.input-range .input-range__label--max .input-range__label-container {
  left: 0;
}

.search-form {
  margin-bottom: 1em;
}

.bmi-row {
  padding: 0 0 2em 0;
  .bmi-estimate .input-range__slider {
    background: #000 !important;
    /* padding-top: 0; */
  }
}

.search-submit {
  margin: 2em 0;
}

.search-results {
  margin-bottom: 2em;
}
