.editor-has-focus {
  border-bottom: 2px solid #3498db;
}
.wrapper {
  .editor {
    border: 1px solid #dfdfdf;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .toolbar {
    border: 1px solid #dfdfdf;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    margin-bottom: 0;
    border-bottom: 0;
  }
  &.team {
    .toolbar,
    .editor {
      border-color: rgba($team-yellow, 0.5);
    }
    .editor.has-focus {
      border-color: $team-yellow;
    }
  }
  &.rider {
    .toolbar,
    .editor {
      border-color: rgba($rider-blue, 0.5);
    }
    .editor.has-focus {
      border-color: $rider-blue;
    }
  }
}
