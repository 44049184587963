.team-profile {
  background: #f2f2f2;
  .profile-banner {
    background: linear-gradient(0deg, rgba(37, 27, 33, 0.45), rgba(37, 27, 33, 0.45));
    background-size: cover;
    background-position: 60% 30%;
    padding: 4rem;
    color: #dfdfdf;
    border-bottom: 4px solid $team-yellow;
    .connect,
    .follow {
      padding-top: 1em;
      display: inline-block;
    }
    .follow {
      display: inline-block;
      margin-left: 1em;
    }
  }
  .form-label {
    z-index: 1;
    top: -2rem;
  }
  .team-data {
    border: 1px solid $team-yellow;
    border-left: none;
    border-bottom: none;
    border-radius: 2px;
    background-color: #fff;
    .card {
      border: 1px solid $team-yellow;
      .label {
        background: $team-yellow;
        color: white;
      }
      .label,
      .detail {
        padding: 0 1rem;
      }
    }
    &.summary p {
      margin-top: 1.5rem;
      margin-bottom: 0;
    }
    .result {
      .wrap {
        border: 1px solid $team-yellow-hover;
        .list-group-item {
          padding: 0;
        }
      }
      .card-header {
        background: $team-yellow;
        color: #fff;
      }
    }
  }
  .card {
    .card-header {
      background: #dfdfdf;
      font-weight: bold;
    }
    border: 1px solid #dfdfdf;
    .card-body {
      padding: 10px;
    }
    .card-footer {
      @extend .card-header;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
  .social {
    margin-top: 1rem;
    svg {
      transition: all 0.25s ease-in-out;
      &:hover {
        transform: rotate(10deg);
      }
    }
    .fa-facebook,
    .fa-facebook-square {
      color: #3b5998;
    }
    .fa-twitter,
    .fa-twitter-square {
      color: #00aced;
    }
    .fa-instagram {
      color: #8a3ab9;
    }
    .fa-youtube-square,
    .fa-youtube {
      color: #ff0000;
    }
    .fa-strava {
      color: #ff4900;
    }

    .fa-globe {
      color: dodgerblue;
    }
    .garmin-connect,
    .training-peaks {
      width: 45px;
    }
  }
  .notice-card {
    border: 1px solid #dfdfdf;
  }
}
