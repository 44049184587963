.rider-profile {
  background: #f8f8f8;
  .rider-data {
    background: #fff;
    border-radius: 2px;
    border-top: 1px solid $rider-blue;
    border-right: 1px solid $rider-blue;
    .form-label {
      z-index: 1;
      top: -2rem;
    }
  }
  .riding-details {
    .card {
      border: 1px solid $rider-blue;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 0.5rem;
      .label {
        background: $rider-blue;
        color: #fff;
        width: 100%;
        text-align: center;
        padding: 0 1rem;
      }
      .data {
        white-space: nowrap;
        padding: 0 1rem;
      }
    }
  }
  .rider-biography {
    p {
      margin-top: 1.5rem;
      margin-bottom: 0;
    }
  }
  .profile-banner {
    border-bottom: 4px solid $rider-blue;
    padding: 4rem;
    color: #dfdfdf;
  }

  .profile-photo {
    background-position: center center;
    background-repeat: no-repeat;
    img {
      min-height: 100%;
      min-width: 100%;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=1)';
      filter: alpha(opacity=0);
    }
  }
  .career-highlights {
    .highlights {
      margin-top: 1rem;
    }
    .highlight-card {
      text-align: center;
      display: flex;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      .wreath {
        background: url('/img/icon/wreath.svg') center no-repeat;
        margin: 5px;
        .place {
          position: absolute;
          top: 0;
          left: 0%;
          color: #fff;
          text-shadow: #000 1px 1px;
          margin: 0 auto;
          width: 100%;
          font-size: 1.25em;
        }
      }
      .highlight-footer {
        padding-left: 1em;
        padding-right: 1em;
        background: #000;
        color: #fff;
      }
    }
  }
  .referees hr:last-of-type {
    display: none;
  }
  .rider-social {
    svg {
      transition: all 0.25s ease-in-out;
      &:hover {
        transform: rotate(10deg);
      }
    }
    .fa-facebook,
    .fa-facebook-square {
      color: #3b5998;
    }
    .fa-twitter,
    .fa-twitter-square {
      color: #00aced;
    }
    .fa-instagram {
      color: #8a3ab9;
    }
    .fa-youtube-square,
    .fa-youtube {
      color: #ff0000;
    }
    .fa-strava {
      color: #ff4900;
    }

    .fa-globe {
      color: dodgerblue;
    }
    .garmin-connect,
    .training-peaks {
      width: 45px;
    }
  }
  .social,
  .referees,
  .history,
  .power {
    margin-top: 1rem;
  }
  .connect,
  .follow {
    padding-top: 1em;
    display: inline-block;
  }
  .follow {
    display: inline-block;
    margin-left: 1em;
  }

  .core-details .core-detail-item {
    padding: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .card {
    border: 1px solid #dfdfdf;
  }

  .card-body {
    padding: 10px;
  }

  .card-footer {
    background: #dfdfdf;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold;
  }
  .profile-body .profile-section-title {
    position: relative;
    top: -1.5em;
    padding-left: 2em;
    h5 {
      background-color: var(--primary);
      color: #f2f2f2;
      padding: 0.5em 1em;
      margin: 0;
    }
  }
}
