.dashboard {
  padding-bottom: 2em;
  background: url('/img/backgrounds/pave.jpg');
  .form-label {
    top: -2rem;
    z-index: 1;
  }
  .news,
  .notifications,
  .team-openings,
  .connections,
  .rider-notices,
  .add-notice,
  .current-notices,
  .connection-requests,
  .suggested-connections,
  .recruitment-overview,
  .my-connections {
    background: #f2f2f2;
    border-radius: 2px;
  }
  .recruitment-overview,
  .my-connections {
    .section-title {
      padding-left: 0.5em;
    }
    a {
      color: rgb(47, 47, 47);

      &:hover {
        text-decoration: none;
      }
      .stage-card {
        margin-bottom: 0.5rem;
        background: #fff;
        .stage-name {
          background: #fff;
          border-bottom: 1px dotted #2f2f2f;
          margin: 8px;
        }
        .rider-count {
          padding: 8px;
          background: #2f2f2f;
          color: #dfdfdf;
          font-weight: bold;
        }
      }
    }
  }
  .suggested-connections {
    .list {
      height: 400px;
      max-height: 50vh;
      overflow: auto;
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: #292929;
          border-radius: 2px;
        }
      }
    }
  }
  .news-item {
    background: #fff;
    padding: 8px;
    transition: all 0.25s ease-in-out;
    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(47, 47, 47, 0.45) !important;
    }
    .excerpt {
      padding-top: 0.5rem;
      margin-bottom: 0;
    }
  }
}
