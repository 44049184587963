.bg {
  &.white {
    background-color: var(--white);
  }
}
.border-primary {
  border: 1px solid;
}
.border-team {
  border: 1px solid $team-yellow;
}

.border-rider {
  border: 1px solid $rider-blue;
}
.br-2 {
  border-radius: 2px;
}
