.footer {
  padding: 3rem 0;
  background: #2f2f2f;
  color: #f2f2f2;
  .social {
    .rotate {
      transition: all 0.25s ease-in-out;
      &:hover {
        transform: rotate(10deg);
      }
    }
    .fa-facebook,
    .fa-facebook-square {
      color: #3b5998;
    }
    .fa-twitter,
    .fa-twitter-square {
      color: #00aced;
    }
    .fa-instagram {
      color: #8a3ab9;
    }
    .fa-youtube-square,
    .fa-youtube {
      color: #ff0000;
    }
  }
}
