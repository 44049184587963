.suggested-rider {
  background: #fff;
  padding: 8px;
  a {
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }
  .placeholder {
    color: #ddd;
  }
}
