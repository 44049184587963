.rider-search-card {
  &:hover > * {
    text-decoration: none;
  }
  .name {
    color: #fff;
    background: #000;
    text-align: center;
    padding: 2px;
    width: 100%;
  }
  .rider-meta {
    .meta-item {
      display: flex;
      border-bottom: 1px solid #dfdfdf;
      &:last-of-type {
        border-bottom: none;
      }
      .label,
      .value {
        color: #000;
      }
      .label {
        background: #333333;
        color: #fff;
        text-align: center;
      }
      .value {
        line-height: 24px;
        perspective: 1000px;
        span {
          padding: 2px;
          position: relative;
          display: inline-block;
          width: 100%;
          //   background: #2195de;
          transition: transform 0.3s;
          transform-origin: 50% 0;
          transform-style: preserve-3d;
          text-indent: 0.5rem;
          &::before {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;
            background: #0965a0;
            content: attr(data-hover);
            transition: background 0.3s;
            transform: rotateX(-90deg);
            transform-origin: 50% 0;
          }
        }
        &:hover span,
        &:focus span {
          transform: rotateX(90deg) translateY(-22px);
          &::before,
          &::before {
            background: #28a2ee;
          }
        }
      }
    }
  }
}
