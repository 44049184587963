.notifications {
  .notification-wrapper {
    height: 400px;
    max-height: 60vh;
    overflow: auto;
    padding-bottom: 1em;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #292929;
        border-radius: 2px;
      }
    }
  }
}
