.photo-upload {
  .custom-file {
    .custom-file-label {
      transition: all 0.2s ease-in-out;
      &::after {
        transition: all 0.2s ease-in-out;
      }
    }
    &:hover,
    &:focus {
      .custom-file-label {
        border-color: #375a7f;
      }
      .custom-file-label::after {
        color: #fff;
        background-color: #375a7f;
      }
    }
  }
}
