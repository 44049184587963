.rider-profile-edit-banner {
  background: greenyellow;
  background: linear-gradient(0deg, rgba(37, 27, 33, 0.45), rgba(37, 27, 33, 0.45)),
    url('/img/banners/rrwu23-innsbruck-2018.jpg');
  background-position: cover;
  background-position: 60% 50%;
  margin-bottom: 0;
  border-radius: 0;
  padding: 2em;
  color: #dfdfdf;
}

.rider-profile-edit {
  .account-form {
    background: #fff;
    border-radius: 3px;
    select,
    input,
    textarea,
    .input-group-text {
      border: 1px solid rgba($rider-blue, 0.5);
      border-radius: 2px;
      &:hover,
      &:focus {
        border-color: rgba($rider-blue, 1);
        + .input-group-append .input-group-text {
          border-color: rgba($rider-blue, 1);
        }
      }
    }
    .yes-no-switch {
      .btn-group {
        .btn:not(.active) {
          border-color: $rider-blue;
          color: $rider-blue;
          background: white;
        }
        .btn.active {
          box-shadow: inset 0 0px 0 white, inset 0 -2px 0 white;
        }
      }
    }
  }
  .rider-profile-nav {
    padding-left: 0;
    .nav {
      .nav-item {
        text-align: left;
        background: #fdfdfd;
        border-bottom: 1px solid #dfdfdf;
        border-left: 3px solid transparent;
        transition: height 0ms 400ms, opacity 400ms 0ms;
        &:hover,
        &.active {
          background: $rider-blue;
          border-left: 3px solid white;
          .nav-link {
            color: #fff;
          }
        }
        .nav-link {
          color: $rider-blue-hover;
          svg {
            display: none;
          }
        }
      }
    }
    @media (max-width: 575px) {
      padding-left: 15px;
      .nav {
        margin-bottom: 2rem;
        &.open {
          .nav-item {
            opacity: 1;
            height: auto;
            transition: height 0ms 0ms, opacity 600ms 0ms;
            svg {
              transform: rotate(180deg);
            }
          }
        }
        .nav-item {
          opacity: 0;
          height: 0;
          &.active {
            opacity: 1;
            height: auto;
            svg {
              display: inherit;
              position: absolute;
              right: 25px;
              top: 12px;
            }
          }
        }
      }
    }
  }
}
